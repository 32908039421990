<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible="true"
    width="400px"
    title="編輯串接新制設定"
    @close="$emit('close')"
  >
    <BaseElForm
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <BaseElFormItem label="是否啟用串接新制" prop="enableLiff">
        <el-switch
          v-model="formData.enableLiff"
          active-text="開啟"
          inactive-text="關閉"
        />
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button type="primary" @click="updateLineConfig">儲存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import formUtils from '@/utils/form'
import { cloneDeep } from 'lodash'
import { defineComponent, reactive, onMounted, ref } from 'vue'
import { UpdateClientPageConfig } from '@/api/member/memberCenter'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'EditLineLoginDialog',
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { shopId } = useShop()
    const formRef = ref(null)
    const formData = reactive({
      enableLiff: true,
    })
    const formRules = {}
    const syncForm = () => {
      const config = cloneDeep(props.config)
      formData.enableLiff = !config.enableLiff
    }
    const updateLineConfig = async () => {
      if (!await formUtils.checkForm(formRef.value)) return
      const form = formData
      const [, err] = await UpdateClientPageConfig({
        shopId: shopId.value,
        enableLiff: !form.enableLiff,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功')
      emit('refresh')
      emit('close')
    }
    onMounted(() => {
      syncForm()
    })
    return {
      formRef,
      formData,
      formRules,
      updateLineConfig,
    }
  },
})
</script>

<style lang="scss" scoped>

</style>

<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible="true"
    title="編輯 Line Login Channel 設定"
    @close="$emit('close')"
  >
    <BaseElForm
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <BaseElFormItem label="Channel ID" prop="channelId">
        <BaseElInput
          v-model="formData.channelId"
          class="dialog-input"
          placeholder="請輸入"
        />
      </BaseElFormItem>

      <BaseElFormItem label="Channel Secret" prop="channelSecret">
        <BaseElInput
          v-model="formData.channelSecret"
          class="dialog-input"
          placeholder="請輸入"
        />
      </BaseElFormItem>
      <BaseElFormItem label="外部瀏覽器通知綁定（開啟前請確認已回貼 Callback URL） " prop="enableLoginBotLink">
        <BaseElSwitch
          v-model="formData.enableLoginBotLink"
          active-text="開啟"
          inactive-text="關閉"
        />
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" @click="updateLineConfig">儲存</BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import { UpdateLineLoginConfig } from '@/api/line'
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'EditLineLoginDialog',
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    formData: {
      channelId: null,
      channelSecret: null,
      enableLoginBotLink: false,
    },
    formRules: {
      channelId: noEmptyRules(),
      channelSecret: noEmptyRules(),
    },
  }),
  computed: {
    ...mapGetters(['shop']),
  },
  mounted () {
    this.syncForm()
  },
  methods: {
    async updateLineConfig () {
      if (!await formUtils.checkForm(this.$refs.form)) return
      const form = this.formData
      const [, err] = await UpdateLineLoginConfig({
        shopId: this.shop,
        channelId: form.channelId,
        channelSecret: form.channelSecret,
        enableLoginBotLink: form.enableLoginBotLink,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.$message.success('更新成功')
      this.$emit('refresh')
      this.$emit('close')
    },

    syncForm () {
      const config = cloneDeep(this.config)
      this.formData.channelId = config.channelId
      this.formData.channelSecret = config.channelSecret
      this.formData.enableLoginBotLink = config.enableLoginBotLink
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <section v-loading="loading" class="section-block">
    <SectionTitle @edit="dialog.edit = true" >
      <template #title>
          <div class="flex items-center gap-[8px]">
            <p class="font-medium" style="font-size: 24px">串接新制設定</p>
            <TipInfo>
              <p>新制：會員完成註冊、建立預約後，不會主動觸發消費者傳訊息給店家</p>
            </TipInfo>
          </div>
        </template>
    </SectionTitle>

    <BaseElForm label-position="left" label-width="250px">
      <BaseElFormItem label="是否啟用串接新制" prop="enableLiff">
        <div class="secret-text">
          <span>{{ !config.enableLiff ? '啟用' : '關閉' }}</span>
        </div>
      </BaseElFormItem>
    </BaseElForm>

    <EditLineNewDialog
      v-if="dialog.edit"
      :config="config"
      @close="dialog.edit = false"
      @refresh="getClientPageConfig"
    />
  </section>
</template>

<script>
import EditLineNewDialog from './EditLineNewDialog.vue'
import { GetClientPageConfig } from '@/api/member/memberCenter'
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { useShop } from '@/use/shop'
import TipInfo from '@/components/TipInfo.vue'

export default defineComponent({
  name: 'LineNewSettings',
  components: { EditLineNewDialog, TipInfo },
  setup() {
    const { shopId } = useShop()
    const config = ref({})
    const loading = ref(false)
    const dialog = reactive({
      edit: false,
    })
    const getClientPageConfig = async() => {
      if (loading.value) return
      loading.value = true
      const [res, err] = await GetClientPageConfig({ shopId: shopId.value })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      config.value = res
    }
    onMounted( async() => {
      await getClientPageConfig()
    })
    return {
      config,
      dialog,
      loading,
      getClientPageConfig,
    }
  },
})
</script>

<style lang="scss" scoped>

</style>
